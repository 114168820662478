import React from 'react';
import './main.styles.css';

import Main06_01 from '../../assets/icon/main/main_06_01.svg';
import Main06_02 from '../../assets/icon/main/main_06_02.svg';
import Main06_03 from '../../assets/icon/main/main_06_03.svg';

const Main06Component = ({...props}) : React.ReactElement => {

    return (
        <div className="mainFindCon">            

            <p className='mainFindTitleText'>
                우주펫에서 믿을 수 있는<br/>
                견주/집사님 찾기
            </p>

            <div className="main06SemiRowCon">

                <div className='main06SemiCon'>
                    <p className='main06SemiTitleText'>
                        반려인들만
                    </p>

                    <img alt ="" src={Main06_01} className="main06SemiImg"/>

                    <p className="main06SemiContentText">
                        회원가입 과정에서 반려동물 사진으로<br/>
                        반려인 인증을 진행합니다
                    </p>
                </div>


                <div className='main06SemiCon'>
                    <p className='main06SemiTitleText'>
                        다양한 인증
                    </p>

                    <img alt ="" src={Main06_02} className="main06SemiImg"/>

                    <p className="main06SemiContentText">
                        등록번호, GPS,  핸드폰 인증 등의<br/>
                        인증을 진행합니다
                    </p>
                </div>

                <div className='main06SemiCon'>
                    <p className='main06SemiTitleText'>
                        신뢰지수
                    </p>

                    <img alt ="" src={Main06_03} className="main06SemiImg"/>

                    <p className="main06SemiContentText">
                        돌봄 활동, 프로필 완성도,<br/>
                        인증 여부 등을 종합적으로 반영한<br/> 
                        신뢰 지수를 찾아볼 수 있습니다
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Main06Component