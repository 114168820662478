import React from 'react';

// 컨텐트 이미지
import ContentImage from '../../assets/image/mobile/content/home_content_02.png'

// 스타일
import './mobile.styles.css';

const Content02Component = ({...props}) : React.ReactElement => {

    // 모바일 컴포넌트 
    // 타이틀 컴포넌트 01

    return (
        <div className='main' style={{backgroundColor : "#FFFFFF"}}>            
            
            <p className="contentTitle01">서로 돕는 이웃</p>
            <p className="contentTitle02">우리 동네 반려인들에게<br/>도움 받아보기</p>
            <p className="contentTitle03">반려동물을 키우다 누군가의 도움이 필요할 때,<br/>동네 반려인에게 도움을 받아보세요.</p>

            <div style={{height : "4.0625vw"}}/>

            <img src={ContentImage} alt="컨텐츠" className='content'/>
        </div>
    )
}

export default Content02Component