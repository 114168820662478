import React from 'react';

import HeaderComponent from '../../components/sitter/mobile/header.component';
import WriteComponent from '../../components/sitter/mobile/write/write.component';
import { BrowserView, MobileView } from 'react-device-detect';
import WriteBrowserComponent from '../../components/sitter/browser/write/write.browser.component';
import HeaderBrowserComponent from '../../components/sitter/browser/header.browser.component';


const SitterWriteMobileScreen = ({...props}) : React.ReactElement => {

    return (
        <div style={{overflow : "hidden"}}>
            <BrowserView>
                <div style={{width : 430, margin : "auto"}}>
                    <HeaderBrowserComponent/>
                    <WriteBrowserComponent/>
                </div>
            </BrowserView>

            <MobileView>
                <HeaderComponent/>
                <WriteComponent/>
            </MobileView>
        </div>
    )
}

export default SitterWriteMobileScreen