import React from 'react';

import ZeroMoney from '../../../assets/icon/sitter/mobile/zero_money.svg';
import Calendar from '../../../assets/icon/sitter/mobile/calendar.svg';
import { Typography } from '@mui/material';

const Content02Component = ({...props}) : React.ReactElement => {

    return <div style={styles.screen}>
        <span style={styles.titleTxt}>
            왜 우주펫에서 해야하나요?
        </span>

        <div style={{height : "12.5000vw"}}/>

        <img src={ZeroMoney} style={styles.icon} alt="수수료없음"/>

        <span style={styles.subTitleTxt}>
            수수료 0원
        </span>
        
        <span style={styles.contentTxt}>
            이웃들에게 받은 돌봄비용,<br/>
            그대로 가져가세요
        </span>

        <div style={{height : "13.5000vw"}}/>

        <img src={Calendar} style={styles.icon} alt="수수료없음"/>

        <span style={styles.subTitleTxt}>
            자유롭게 정하는 스케줄
        </span>
        
        <span style={styles.contentTxt}>
            내가 가능한 시간에<br/>
            돌봄 요청이 있다면 연락해보세요
        </span>
    </div>
}

const styles : Record<string, React.CSSProperties> = {
    screen : {
        width : "100vw",
        backgroundColor : "white",
        display : "flex",
        flexDirection : "column",
        justifyContent : "center",
        alignItems : "center",
        paddingTop : "7.8125vh",
        paddingBottom : "9.2188vh",
    },
    titleTxt : {
        fontSize : "6.8750vw",
        fontWeight : 700,
        letterSpacing : -0.3,
        alignSelf : "flex-start",
        paddingLeft : "7.5000vw",
        lineHeight : "140%"
    },
    subTitleTxt : {
        fontSize : "5.6250vw",
        fontWeight : 700,
        color : "#9F6BFF",
        letterSpacing : -0.3,
        marginTop : "3.7500vw",
        marginBottom : "1.8750vw",
        lineHeight : "120%"
    },
    contentTxt : {
        fontSize : "4.3750vw",
        fontWeight : 400,
        color : "#62686D",
        letterSpacing : -0.3,
        textAlign : "center",
        lineHeight : "140%"
    },
    icon : {
        width : "18.1250vw",
        height : "18.1250vw",
    }
}

export default Content02Component;