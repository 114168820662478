import React from 'react';

// 컨텐트 이미지
import TitleLogo from '../../assets/icon/logo/mobile_download_logo.svg';
import Button from '../../assets/icon/mobile/footer/button.svg'

// 스타일
import './mobile.styles.css';

const FooterComponent = ({...props}) : React.ReactElement => {

    // 모바일 컴포넌트 
    // 타이틀 컴포넌트 01


    const onMain = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const pressLink = () => {
        window.open("https://cosmicnyang.page.link/QYkB","_blank")
    }

    return (
        <div className='footer'>
            <img src={TitleLogo} alt="로고" className="footerLogo" onClick={() => onMain()}/>
            <img src={Button} alt="버튼" className="downloadFooterBtn" onClick={() => pressLink()}/>
        </div>
    )
}

export default FooterComponent