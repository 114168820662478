import React from 'react';
import { Typography } from '@mui/material';

import BackgroundImage from '../../../assets/images/sitter/mobile/background.png';
import './sitter.styles.css'

const Content01Component = ({...props}) : React.ReactElement => {

    return <div style={styles.screen}>

        <span style={styles.titleTxt}>
            우리 동네, 펫시터<br/>
            지금 시작해보세요
        </span>

        <div style={{height : "0.9375vh"}}/>

        <span style={styles.contentTxt}>
            우주펫에서 이웃시터가 되어<br/>
            우리동네 반려인들과 따뜻한 경험을 나눠보세요.
        </span>

        <img src={BackgroundImage} style={styles.backgroundImage} alt="배경"/>


    </div>
}

const styles : Record<string, React.CSSProperties> = {
    screen : {
        width : "100vw",
        height : "100vh",        
        display : "flex",
        flexDirection : "column",
        justifyContent : "flex-start",
        alignItems : "flex-start",
        paddingTop : "10.0625vh",
        boxSizing : "border-box",
    },
    titleTxt : {
        fontSize : "6.8750vw",
        fontWeight : 700,
        letterSpacing : -0.3,
        paddingLeft : "7.5000vw",
        lineHeight : "140%"
    },
    contentTxt : {
        fontSize : "4.3750vw",
        fontWeight : 400,
        letterSpacing : -0.3,
        color : "#62686D",
        paddingLeft : "7.5000vw",
        lineHeight : "140%"
    },
    backgroundImage : {
        width : "100vw",
        height : "auto",
        position : "absolute", 
        bottom : 0,
        overflow : "hidden",
        marginTop : 0,
        marginBottom : 0,
        paddingTop : 0,
        paddingBottom : 0,
        boxSizing : "border-box",        
        zIndex : -10
    }
}

export default Content01Component;