import React, { forwardRef, useImperativeHandle } from "react";
import AliceCarousel from "react-alice-carousel";
import 'react-alice-carousel/lib/alice-carousel.css';
import './common.styles.css';

export interface CarouselAction {
    slideNext: () => void;
    slidePrev: () => void;
}
  

const handleDragStart = (e : any) => e.preventDefault();

const handleClickImage = (index : number) => {
    if (index === 0) {
        window.open("https://blog.naver.com/spacepetofficial/222910857769",  "_blank")
    }
    else if (index === 1) {
        window.open("https://blog.naver.com/spacepetofficial/222929495479",  "_blank")
    }
    else if (index === 2) {
        window.open("https://blog.naver.com/spacepetofficial/222916631717",  "_blank")
    }
    else if (index === 3) {
        window.open("https://blog.naver.com/spacepetofficial/222929495259",  "_blank")
    }
    else if (index === 4) {
        window.open("https://blog.naver.com/spacepetofficial/222922143581",  "_blank")
    }
}

const items = [
    <div className="carouselItem">
        <img src={require("../../assets/images/main/main_07_01.png")} onDragStart={handleDragStart} role="presentation" alt="" onClick={() => handleClickImage(0)} className="carouselImg"/>
    </div>,
    <div className="carouselItem">
        <img src={require("../../assets/images/main/main_07_02.png")} onDragStart={handleDragStart} role="presentation" alt="" onClick={() => handleClickImage(1)} className="carouselImg"/>
    </div>,
    <div className="carouselItem">
        <img src={require("../../assets/images/main/main_07_03.png")} onDragStart={handleDragStart} role="presentation" alt="" onClick={() => handleClickImage(2)} className="carouselImg"/>
    </div>,
    <div className="carouselItem">
        <img src={require("../../assets/images/main/main_07_04.png")} onDragStart={handleDragStart} role="presentation" alt="" onClick={() => handleClickImage(3)} className="carouselImg"/>
    </div>,
    <div className="carouselItem">
        <img src={require("../../assets/images/main/main_07_05.png")} onDragStart={handleDragStart} role="presentation" alt="" onClick={() => handleClickImage(4)} className="carouselImg"/>
    </div>,
];    


const thumbItems = (items : any, [setThumbIndex, setThumbAnimation] : any) => {
    return items.map((item : any, i : number) => (
        <div className="thumb" onClick={() => (setThumbIndex(i), setThumbAnimation(true))}>
            {item}
        </div>
    ));
};

export const Carousel = forwardRef<CarouselAction>((props, ref) => {

    const carousel = React.useRef<any | null>(null);

    const [thumbIndex, setThumbIndex] = React.useState(0);
    const [thumbAnimation, setThumbAnimation] = React.useState(false);
    const [thumbs] = React.useState(thumbItems(items, [setThumbIndex, setThumbAnimation]));

    useImperativeHandle(ref, () => ({
        slideNext,
        slidePrev
    }))

    const slideNext = () => {
        carousel?.current?.slideNext();
    };

    const slidePrev = () => {
        carousel?.current?.slidePrev();
    };
    

    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 },
    };
   

    return (
        <div style={{width : "100%"}}>
            <AliceCarousel
                mouseTracking 
                disableDotsControls
                disableButtonsControls                
                responsive={responsive}
                infinite
                items={thumbs}
                paddingLeft={0}
                paddingRight={0}
                autoPlay
                autoPlayInterval={1500}
                ref={carousel}
            />
        </div>
    )

    })
