import React from "react";
import "./term.styles.css";

const DeleteTermScreen = ({...props}) : React.ReactElement => {


    return (
        <div className="termCon">
            
            <div className="titleCon">
                <p className="titleText">개인정보삭제처리방침</p>
            </div>

            <div className="semiTitleCon">
                <p className="semiTitleText">우주펫 개인정보 삭제처리방침</p>

                <p className="contentText">"주식회사 우주펫(Spacepet LTD)”(이하 “회사”)은 다음과 같은 방법으로 이용자의 개인정보 삭제 방법을 제공합니다.<br/>사용자가 계정 삭제를 요청하기 위해 취해야 할 단계는 다음과 같습니다.</p>
            
            </div>

            <div className="semiTitleCon">
                
                <p className="semiTitleText">1. 앱 내 회원탈퇴 기능을 통한 데이터 삭제</p>
                <p className="contentText">우주펫은 앱 내 회원탈퇴 기능을 통해 유저의 회원 탈퇴를 진행하며 개인정보를 삭제기능을 지원합니다.</p>

                <br/>

                <div className="contentCon">
                    <p className="contentSequenceNum">•</p>
                    <p className="contentText">동의정보 철회를 통한 데이터 삭제</p>                    
                </div>

                <div className="contentCon">
                    <p className="contentSequenceNum">1.</p>
                    <p className="contentText">앱 내 "더보기"탭으로 이동</p>
                </div>

                <div className="contentCon">
                    <p className="contentSequenceNum">2.</p>
                    <p className="contentText">오른쪽 상단 설정(톱니바퀴 아이콘) 터치</p>
                </div>

                <div className="contentCon">
                    <p className="contentSequenceNum">3.</p>
                    <p className="contentText">최하단 "회원탈퇴" 버튼 터치</p>
                </div>

                <div className="contentCon">
                    <p className="contentSequenceNum">4.</p>
                    <p className="contentText">추가 팝업창 내용 확인 뒤 "회원탈퇴" 버튼 터치</p>
                </div>

                <br/>

                <p className="semiTitleText">2. 이메일을 통한 데이터 삭제</p>
                <p className="contentText"></p>

                <div className="contentCon">
                    <p className="contentSequenceNum">•</p>
                    <p className="contentText">이름 : 고범석<br/>직위 : 개인정보보호 책임자<br/>부서 : 개발팀<br/>연락처 : spacepetofficial@gmail.com</p>
                </div>

                <div className="contentCon">
                    <p className="contentSequenceNum">•</p>
                    <p className="contentText">운영자에게 이메일로 문의주시면 지체없이 조치 해 드리도록 하겠습니다.</p>                    
                </div>
                
                <br/>
            </div>

            

            <div className="dateCon">
                <p className="dateText">공고일자 2023년 08월 11일</p>
                <p className="dateText">시행일자 2023년 08월 11일</p>
            </div>
            

        </div>
    )
}

export default DeleteTermScreen;