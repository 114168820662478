import React from 'react';

// 컨텐트 이미지
import ContentImage from '../../assets/image/mobile/content/home_content_download.png'

import Apple from '../../assets/icon/component/download/apple.svg';
import Google from '../../assets/icon/component/download/google.svg';

// 스타일
import './mobile.styles.css';
import { AppStoreLink, AppStoreWebLink, PlayStoreLink, PlayStoreWebLink } from '../../data/urls';

const ContentDownloadComponent = ({...props}) : React.ReactElement => {

    // 모바일 컴포넌트 
    // 타이틀 컴포넌트 01

    const pressApple = () => {
        window.open(AppStoreLink, "_blank")        
    }

    const pressGoogle = () => {
        window.open(PlayStoreLink,  "_blank")
    }


    return (
        <div className='main' style={{backgroundColor : "#C7B4FF"}}>            
            
            <div style={{paddingLeft : "7.5vw", paddingRight : "7.5vw"}}>
                <p className="contentTitle04">지금 우주펫에서</p>

                <p className="contentTitle05">우리동네 견주/집사님&nbsp;<p className="contentTitle04">찾아보고</p></p>

                <p className="contentTitle04">소통할 이웃 반려인을 만나보세요</p>
            </div>          

            <div style={{height : "7.5vw"}}/>

            <div className="download">
                <img src={Apple} alt="애플다운로드" className="downloadButton" onClick={() => pressApple()}/>
                <div style={{width : "3.1250vw"}}/>
                <img src={Google} alt="구글다운로드" className="downloadButton" onClick={() => pressGoogle()}/>
            </div>

            <div style={{height : "12.5vw"}}/>

            <img src={ContentImage} alt="컨텐츠" className='content'/>
        
            <div style={{height : "7.5vw"}}/>
        </div>
    )
}

export default ContentDownloadComponent