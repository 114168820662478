import { Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = ({...props}) : React.ReactElement => {
    
    const navigate = useNavigate();    
    const [scroll, setScroll] = React.useState<number>(0)

    React.useEffect(() => {
        window.addEventListener("scroll", scrollEvent)
        return () => window.removeEventListener("scroll", scrollEvent)
    }, []);

    const scrollEvent = () => {
        var scroll = window.scrollY;
        setScroll(scroll)
    }

    const onButton = () => {
        navigate("/sitter/write")
    }

    const styles : Record<string, React.CSSProperties> = {
        "button" : {
            width : (40 - scroll > 0)? `${100 - ((40 - scroll)/4)}%` : "100%",
            backgroundColor : "#9F6BFF",
            paddingTop : "2.4969vh",
            paddingBottom : "2.4969vh",
            position : "fixed",
            display : "flex",
            alignItems : "center",
            justifyContent : "center",
            alignSelf : "center",
            bottom : (20 - scroll > 0)? (20 - scroll) : 0,
            borderRadius : (40 - scroll > 0)?  (40 - scroll) / 5 : 0,
            left: "50%",
            transform: "translate(-50%, 0)",
            margin : "auto",
            zIndex : 999
        },
        "buttonTxt" : {
          fontSize : "5vw",
          fontWeight : 700,
          letterSpacing : "-1%",
          textAlign : "center",
          color : "white",
          lineHeight : "120%"
        }
    }

    return <div style={styles.button} onClick={() => onButton()}>
        <span style={styles.buttonTxt}>
          이웃시터 지원하기
        </span>
    </div>
}




export default Footer;