import React from 'react';
import { Carousel, CarouselAction } from '../common/carousel.component';
import "./main.styles.css";

import AngleLeft from '../../assets/icon/common/angle_left.svg';
import AngleRight from '../../assets/icon/common/angle_right.svg';

const Main07Component = ({...props}) : React.ReactElement => {

    const childComponentRef = React.useRef<any>(null);

    const pressMore = () => {
        window.open("https://blog.naver.com/spacepetofficial",  "_blank")
    }

    const pressNext = () => {
        childComponentRef.current?.slideNext();
    }

    const pressPrev = () => {
        childComponentRef.current?.slidePrev();
    }


    return (
        <div className="main07Con">

            <p className="main07TitleText">
                우주펫 유저들의 후기
            </p>

            <div className="mainCarouselCon">
                <img src={AngleLeft} alt="" onClick={() => pressPrev()}/>
                <Carousel ref={childComponentRef}/>
                <img src={AngleRight} alt="" onClick={() => pressNext()}/>
            </div>

            <div className="main07BtnCon" onClick={() => pressMore()}>
                <p className="main07BtnText">
                    더 많은 후기 보러 가기
                </p>
            </div>

        </div>
    )
}

export default Main07Component