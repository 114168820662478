import React from 'react';
import './main.styles.css';

import Main05Text from '../../assets/icon/main/main_05.svg';


const Main05Component = ({...props}) : React.ReactElement => {


    return (
        <div className="mainCon">            

            <img alt="" src={Main05Text} className="main05Content"/>

            <img alt="" src={require("../../assets/images/main/main_05.png")} className="mainImage" width={710}/>


        </div>
    )
}

export default Main05Component