import React from 'react';
import './index.css';
import App from './App';
import { render } from "react-dom";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';
import ScrollTop from './model/scroll.model';

const rootElement = document.getElementById("root");

  render(
    <CookiesProvider>
      <BrowserRouter>
        <ScrollTop />
        <React.StrictMode>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </React.StrictMode>
      </BrowserRouter>      
    </CookiesProvider>, 
    rootElement
  );



reportWebVitals();
