import React from 'react';

import TitleLogo from '../../../assets/icon/sitter/mobile/title_logo.svg';
import { useNavigate } from 'react-router-dom';


const HeaderBrowserComponent = ({...props}) : React.ReactElement => {

    const navigate = useNavigate();

    const pressMain = () => {
        navigate("/")
    }


    return <div style={styles.header}>
        <img src={TitleLogo} style={styles.titleLogo} alt="로고" onClick={() => pressMain()}/>
    </div>
}

const styles : Record<string, React.CSSProperties> = {
    "header" : {
        minWidth : 430,
        maxWidth : 430,
        height : 60,
        backgroundColor : "white",
        display : 'flex',
        top : 0,
        position : "fixed",
        paddingTop : 17,
        paddingBottom : 13,
        paddingLeft : 24,
        zIndex : 1000,
        boxSizing : "border-box",
        left: "50%",
        transform: "translate(-50%, 0)",
    },
    "titleCon" : {
        display : "flex",
        boxSizing : "border-box",
        paddingBottom : 0,
    },
    "titleLogo" : {
        width : 96,
        height : 30,
        cursor : "pointer"
    }
}

export default HeaderBrowserComponent;