import React from 'react';

// 컨텐트 이미지
import ContentImage from '../../assets/image/mobile/content/home_content_04.png'

// 스타일
import './mobile.styles.css';

const Content04Component = ({...props}) : React.ReactElement => {

    // 모바일 컴포넌트 
    // 타이틀 컴포넌트 01

    return (
        <div className='main' style={{backgroundColor : "#FFFFFF"}}>            
            <p className="contentTitle01">돌봄 요청</p>
            <p className="contentTitle02">급한 돌봄이 필요할 때<br/>돌봄 요청하기</p>
            <p className="contentTitle03">아이를 돌봐줄 사람이 당장 필요하신가요?<br/>우주펫 이웃들에게 도움을 요청 해보세요🚨</p>

            <div style={{height : "4.0625vw"}}/>

            <img src={ContentImage} alt="컨텐츠" className='content'/>
        </div>
    )
}

export default Content04Component