import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import HomeScreen from './screens/home/home.screen';
import DeleteTermScreen from './screens/terms/deleteTerm.screen';
import UcMainScreen from './screens/uc/main.screen';
import SitterScreen from './screens/sitter/sitter.screen';
import SitterWriteMobileScreen from './screens/sitter/sitter_write.mobile.screen';
import SitterWriteDoneMobileScreen from './screens/sitter/sitter_write_done.mobile.screen';

const App = ({...props}) : React.ReactElement => { 

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/deleteTerm" element={<DeleteTermScreen />} />
        <Route path="/uc" element={<UcMainScreen />} />
        <Route path="/sitter" element={<SitterScreen/>} />
        <Route path="/sitter/write" element={<SitterWriteMobileScreen/>} />
        <Route path="/sitter/done" element={<SitterWriteDoneMobileScreen/>} />
      </Routes>    
    </div>
  );
}

export default App;