import React from 'react';

import QuestionOnIcon from '../../../assets/icon/sitter/mobile/question_on.svg';
import QuestionOffIcon from '../../../assets/icon/sitter/mobile/question_off.svg';



const Content07Component = ({...props}) : React.ReactElement => {

    const [visible01, setVisible01] = React.useState(false);
    const [visible02, setVisible02] = React.useState(false);
    const [visible03, setVisible03] = React.useState(false);
    const [visible04, setVisible04] = React.useState(false);    

    return <div style={styles.screen}>
        <span style={styles.title}>
            자주 묻는 질문
        </span>

        <div style={{height : "4.6875vh"}}/>

        <div style={visible01? styles.selectedCon : styles.unSelectedCon} onClick={() => {setVisible01(!visible01)}}>
            <div style={styles.rowCon}>
                {
                    (visible01)? <img src={QuestionOnIcon} style={styles.questionIcon} alt="?"/> : <img src={QuestionOffIcon} style={styles.questionIcon} alt="?"/>
                }
                <div style={styles.colCon}>
                    <span style={(visible01)? styles.titleTxt : styles.unSelectInfoTxt}>
                        중개 비용 및 중개 수수료가 있나요?
                    </span>
                    {
                        (visible01)? 
                        <span style={styles.contentTxt}>
                            없어요. 우주펫은 채팅 및 돌봄거래의 모든 과정에서 이용료 및 수수료를 받고 있지 않아요.
                        </span>
                        : null
                    }
                    
                </div>
            </div>
        </div>

        <div style={visible02? styles.selectedCon : styles.unSelectedCon} onClick={() => {setVisible02(!visible02)}}>
            <div style={styles.rowCon}>
                {
                    (visible02)? <img src={QuestionOnIcon} style={styles.questionIcon} alt="?"/> : <img src={QuestionOffIcon} style={styles.questionIcon} alt="?"/>
                }
                <div style={styles.colCon}>
                    <span style={(visible02)? styles.titleTxt : styles.unSelectInfoTxt}>
                    돌봄이 필요한 이웃은 어떻게 찾나요?
                    </span>
                    {
                        (visible02)? 
                        <div style={styles.colCon}>
                            <div style={styles.rowCon}>
                                <span style={styles.numTxt}>
                                    1.
                                </span>
                                <span style={styles.contentTxt}>
                                    돌봄 요청글을 보고 이웃시터님이 이웃에게 연락할 수 있어요.
                                </span>
                            </div>
                            <div style={styles.rowCon}>
                                <span style={styles.numTxt}>
                                    2.
                                </span>
                                <span style={styles.contentTxt}>
                                    돌봄이 필요한 이웃이 이웃시터님의 프로필을 보고 채팅을 통해 연락할 수 있어요.
                                </span>
                            </div>
                        </div>
                        : null
                    }                    
                </div>
            </div>
        </div>


        <div style={visible03? styles.selectedCon : styles.unSelectedCon} onClick={() => {setVisible03(!visible03)}}>
            <div style={styles.rowCon}>
                {
                    (visible03)? <img src={QuestionOnIcon} style={styles.questionIcon} alt="?"/> : <img src={QuestionOffIcon} style={styles.questionIcon} alt="?"/>
                }
                <div style={styles.colCon}>
                    <span style={(visible03)? styles.titleTxt : styles.unSelectInfoTxt}>
                        이웃시터가 뭔가요?
                    </span>
                    {
                        (visible03)? 
                        <span style={styles.contentTxt}>
                            이웃시터란 우주펫에서 활동하는 펫시터를 의미해요.
                        </span>
                        : null
                    }
                    
                </div>
            </div>
        </div>

        <div style={visible04? styles.selectedCon : styles.unSelectedCon} onClick={() => {setVisible04(!visible04)}}>
            <div style={styles.rowCon}>
                {
                    (visible04)? <img src={QuestionOnIcon} style={styles.questionIcon} alt="?"/> : <img src={QuestionOffIcon} style={styles.questionIcon} alt="?"/>
                }
                <div style={styles.colCon}>
                    <span style={(visible04)? styles.titleTxt : styles.unSelectInfoTxt}>
                        비용은 어떻게 받나요?
                    </span>
                    {
                        (visible04)? 
                        <span style={styles.contentTxt}>
                            이웃과 사전에 합의된 금액으로 직접 거래해요.
                        </span>
                        : null
                    }
                    
                </div>
            </div>
        </div>

        

    </div>
}

const styles : Record<string, React.CSSProperties> = {
    screen : {
        width : "100vw",
        backgroundColor : "white",
        display : "flex",
        flexDirection : "column",
        boxSizing : "border-box",
        paddingTop : "4.6875vh",
        paddingBottom : "7.8125vh",
    },
    title : {
        fontSize : "6.8750vw",
        fontWeight : 700,
        letterSpacing : -0.3,
        alignSelf : "flex-start",
        paddingLeft: "7.5vw",
        lineHeight : "120%"
    },
    titleTxt : {
        fontSize : "5vw",
        fontWeight : 600,
        letterSpacing : -0.3,        
        color : "#000000",
        lineHeight : "140%"
    },
    unSelectInfoTxt : {
        fontSize : "5vw",
        fontWeight : 400,
        letterSpacing : -0.3,
        color : "#454A50",
        lineHeight : "140%"
    },
    numTxt : {
        fontSize : "4.3750vw",
        fontWeight : 400,
        letterSpacing : -0.3,
        color : "#62686D",
        marginTop : "2.5vw",
        marginRight : "1vw"
    },
    contentTxt : {
        fontSize : "4.3750vw",
        fontWeight : 400,
        letterSpacing : -0.3,
        color : "#62686D",
        marginTop : "2.5vw",
        lineHeight : "150%"
    },
    unSelectedCon : {
        width : "100%",
        paddingTop : "4.3750vw",
        paddingBottom : "4.3750vw",
        paddingLeft: "7.5vw",
        paddingRight : "7.5vw",       
        display : "flex",
        flexDirection : "row",
        alignItems : "flex-start",
        justifyContent : "flex-start",
        boxSizing : "border-box"
    },
    selectedCon : {
        width : "100%",
        backgroundColor : "#FAFAFB",
        paddingLeft: "7.5vw",
        paddingRight : "7.5vw",  
        paddingTop : "4.3750vw",
        paddingBottom : "6.2500vw",
        display : "flex",
        flexDirection : "row",
        alignItems : "flex-start",
        justifyContent : "flex-start",  
        boxSizing : "border-box"
    },
    rowCon : {
        display : "flex",
        flexDirection : 'row',
        alignItems : "flex-start",
        justifyContent : "flex-start"  
    },
    colCon : {
        display : "flex",
        flexDirection : 'column',
        alignItems : "flex-start",
        justifyContent : "flex-start"  
    },
    questionIcon : {
        width : "6.8750vw",
        height : "6.8750vw",
        boxSizing : "border-box",
        marginRight : "1.8750vw",
    }

}

export default Content07Component;