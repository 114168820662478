import React from 'react';

// 컨텐트 이미지
import ContentImage from '../../assets/image/mobile/content/home_content_08.png'

// 스타일
import './mobile.styles.css';

const Content08Component = ({...props}) : React.ReactElement => {

    // 모바일 컴포넌트 
    // 타이틀 컴포넌트 01

    return (
        <div className='main' style={{backgroundColor : "#F9F4FF"}}>            
            <p className="contentTitle01">이웃 강아지・고양이</p>
            <p className="contentTitle02">이웃의 강아지・고양이<br/>둘러보기</p>
            <p className="contentTitle03">우리 아이랑 잘 맞을 것 같은 친구들을 선택해<br/>볼 수 있어요. 동네 친구들을 만나보세요!</p>

            <div style={{height : "4.0625vw"}}/>

            <img src={ContentImage} alt="컨텐츠" className='content'/>
        </div>
    )
}

export default Content08Component