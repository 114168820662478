import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useSearchParams } from 'react-router-dom';
import'./uc.styles.css';

const UcMainScreen = ({...props}) : React.ReactElement => {

    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const isMobile = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };

    const onClick = () => {
        if (isMobile()) {
            var linkQuery = searchParams.get("deepLink")
            window.open("https://cosmicnyang.page.link/" + linkQuery)
        }
        else {
            window.open("https://spacepetad1.onelink.me/MLvZ/challenge")
        }
    }

    return <>
        <Helmet>
            <title>우주펫 응가 치우기 챌린지</title>
            <meta name="우주펫 응가 치우기 챌린지" content="우주펫 응가 치우기 챌린지"/>
            <meta name="description" content="챌린지 참여하고 배변용품 받기"/>
            <meta property="og:type" content="website"/> 
            <meta property="og:title" content="우주펫 응가 치우기 챌린지"/>
            <meta property="og:url" content="https://spacepet.site/uc"/>
            <meta property="og:description" content="챌린지 참여하고 배변용품 받기"/>
            <meta property="og:image" content="https://nhlfpiauavry9180994.cdn.ntruss.com/web/%23%EC%9D%91%EC%B1%8C_%EC%9B%B9%EB%A7%81%ED%81%AC%20%EC%9D%B4%EB%AF%B8%EC%A7%80.png"/>
            <meta property="og:site_name" content="우주펫 응챌"/>
        </Helmet>

        <div className="ucMainCon">
            <div className="con01">
                <div className="con03"/>

                <img src={require("../../assets/images/uc/main_content.png")} className="mainConImage" alt=""/>
                
                <div className="con02">                  
                    <p className="subText01">* 우주펫은 반려인만 이용할 수 있어요. </p>
                    <div className="button01" onClick={() => {onClick()}}>
                        
                        <p className="button01Txt">배변용품 받으러 가기</p>
                    </div>      
                </div>      
            </div>    
        </div>
    </>

}

export default UcMainScreen;