import React from 'react';
import './main.styles.css';

import Main03Text from '../../assets/icon/main/main_03.svg';


const Main03Component = ({...props}) : React.ReactElement => {


    return (
        <div className="mainCon2">            

            <img alt="" src={require("../../assets/images/main/main_03.png")} className="mainImage" width={710}/>

            <img alt="" src={Main03Text} className="main03Content"/>

        </div>
    )
}

export default Main03Component