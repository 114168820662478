import React from 'react';

import QuestionColor from '../../assets/icon/mobile/faq/quertion_color.svg';
import Question from '../../assets/icon/mobile/faq/question.svg';

// 스타일
import './mobile.styles.css';

const ContentFaqComponent = ({...props}) : React.ReactElement => {

    const titles = [
        "돌봄품앗이가 뭔가요?",
        "우주펫은 무료서비스인가요?",
        "현재 오픈된 지역은 어디인가요? 다른 지역은 언제 열리나요?",
        "반려동물을 키운 경험이 있거나, 본가에 반려동물이 있는데 서비스 이용이 가능한가요?"
    ];

    const contents = [
        "반려동물 돌봄이 필요할 때, 우리동네 견주/집사님과 돌봄을 주고 받는 것을 의미해요. 우주펫에서 친하게 지낼 이웃 반려인을 찾아보세요.",
        "우주펫의 품앗이 이웃 찾기는 무료 서비스에요. 부담없이 우리동네 반려인들과 소통을 시작해보세요.",
        "우주펫은 현재 전국에서 서비스를 제공하고 있어요.",
        "네, 현재 반려동물을 키우고 있지 않더라도 반려동물을 키운 경험만 있다면 우주펫 가입이 가능해요. 다만 반려동물을 키운 경험이 없는 경우, 서비스 이용이 제한돼요."
    ];

    const [visible01, setVisible01] = React.useState(false);
    const [visible02, setVisible02] = React.useState(false);
    const [visible03, setVisible03] = React.useState(false);
    const [visible04, setVisible04] = React.useState(false);

    const press01 = () => {
        setVisible01(!visible01);
    }

    const press02 = () => {
        setVisible02(!visible02);
    }

    const press03 = () => {
        setVisible03(!visible03);
    }

    const press04 = () => {
        setVisible04(!visible04);
    }


    return (
        <div className="faq">

            <p className="faqTitle">자주 묻는 질문</p>

            <div style={{height : "9.3750vw"}}/>
            
            <div className={(visible01)? "faqCon01" : "faqCon"} onClick={() => press01()}>

                <img src={(visible01)? QuestionColor : Question } alt="로고" className="faqIcon"/>

                <div className="faqCon02">
                    <p className={(visible01)? "faqTitle02" : "faqTitle01"}>{titles[0]}</p>

                    {(visible01)?
                        <p className="faqContent">{contents[0]}</p>
                    :
                        null
                    }
                </div>
            </div>

            <div className={(visible02)? "faqCon01" : "faqCon"} onClick={() => press02()}>

                <img src={(visible02)? QuestionColor : Question } alt="로고" className="faqIcon"/>

                <div className="faqCon02">
                    <p className={(visible02)? "faqTitle02" : "faqTitle01"}>{titles[1]}</p>

                    {(visible02)?
                        <p className="faqContent">{contents[1]}</p>
                    :
                        null
                    }
                </div>
            </div>

            <div className={(visible03)? "faqCon01" : "faqCon"} onClick={() => press03()}>

                <img src={(visible03)? QuestionColor : Question } alt="로고" className="faqIcon"/>

                <div className="faqCon02">
                    <p className={(visible03)? "faqTitle02" : "faqTitle01"}>{titles[2]}</p>

                    {(visible03)?
                        <p className="faqContent">{contents[2]}</p>
                    :
                        null
                    }
                </div>
            </div>

            <div className={(visible04)? "faqCon01" : "faqCon"} onClick={() => press04()}>

                <img src={(visible04)? QuestionColor : Question } alt="로고" className="faqIcon"/>

                <div className="faqCon02">
                    <p className={(visible04)? "faqTitle02" : "faqTitle01"}>{titles[3]}</p>

                    {(visible04)?
                        <p className="faqContent">{contents[3]}</p>
                    :
                        null
                    }
                </div>
            </div>

        </div>
    )
}

export default ContentFaqComponent