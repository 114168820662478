import React from 'react';
import './main.styles.css';

import Main04Text from '../../assets/icon/main/main_04.svg';


const Main04Component = ({...props}) : React.ReactElement => {


    return (
        <div className="mainCon2">

            <img alt="" src={require("../../assets/images/main/main_04.png")} className="mainImage" width={710}/>

            <img alt="" src={Main04Text} className="main04Content"/>

        </div>
    )
}

export default Main04Component