import React from 'react';

// 컨텐트 이미지
import ContentImage from '../../assets/image/mobile/content/home_content_03.png'

// 스타일
import './mobile.styles.css';

const Content03Component = ({...props}) : React.ReactElement => {

    // 모바일 컴포넌트 
    // 타이틀 컴포넌트 01

    return (
        <div className='main' style={{backgroundColor : "#FFFFFF"}}>            
            
            <p className="contentTitle01">주고받는 돌봄품앗이</p>
            <p className="contentTitle02">이웃 반려인들과<br/>돌봄 주고받기</p>
            <p className="contentTitle03">여기 품앗이를 원하는 이웃들이 모여있어요.<br/>우리 동네 사람들과 도움을 주고받아 보세요.</p>

            <div style={{height : "4.0625vw"}}/>

            <img src={ContentImage} alt="컨텐츠" className='content'/>
        </div>
    )
}

export default Content03Component