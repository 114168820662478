import React from 'react';
import HomeBroswserScreen from "./home.broswer.screen"
import HomeMobileScreen from "./home.mobile.screen"
import { Helmet } from 'react-helmet-async';
import { BrowserView, MobileView } from 'react-device-detect';

const HomeScreen = ({...props}) : React.ReactElement => {

    const [isMobile, setIsMobile] = React.useState<boolean>(false);

    React.useEffect(() => {
        window.addEventListener('resize', handleResize);

        if (window.innerWidth <= 1024) {
            setIsMobile(true);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        }      
    }, []);

    const handleResize = () => {
        console.log(window.innerWidth)
        if (window.innerWidth <= 1024) {
            setIsMobile(true)
        }
        else {
            setIsMobile(false)
        }
    }

    return (
        <>
            <Helmet>
                <title>우리 주변의 펫, 우주펫</title>
                <meta name="우리 주변의 펫, 우주펫" content="우리동네 반려동물 돌봄 커뮤니티"/>
                <meta name="description" content="이웃과 주고받는 펫 돌봄, 강아지 산책 친구, 반려동물 정보교류 등 동네기반 펫 돌봄 커뮤니티"/>
                <meta property="og:type" content="website"/> 
                <meta property="og:title" content="우리 주변의 펫, 우주펫"/>
                <meta property="og:url" content="https://spacepet.site/"/>
                <meta property="og:description" content="이웃과 주고받는 펫 돌봄, 강아지 산책 친구, 반려동물 정보교류 등 동네기반 펫 돌봄 커뮤니티"/>
                <meta property="og:image" content="https://nhlfpiauavry9180994.cdn.ntruss.com/web/01.png"/>
                <meta property="og:site_name" content="테스트"/>
            </Helmet>

            <BrowserView>
                {
                    isMobile?  <HomeMobileScreen /> : <HomeBroswserScreen />
                }            
            </BrowserView> 
            
            <MobileView>
                <HomeMobileScreen />
            </MobileView>
        </>
    )
}

export default HomeScreen;