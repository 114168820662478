import React from 'react';
import './main.styles.css';

import Main04Text from '../../assets/icon/main/together_info.svg';


const TogetherComponent = ({...props}) : React.ReactElement => {


    return (
        <div className="mainCon">

            <img alt="" src={Main04Text} className="mainTogetherContent"/>

            <img alt="" src={require("../../assets/images/main/together_info.png")} className="mainImage" width={710}/>

        </div>
    )
}

export default TogetherComponent