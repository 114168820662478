import React from 'react';

import TitleComponent from '../../components/mobile/title.component';
import Content02Component from '../../components/mobile/content_02.component';
import Content03Component from '../../components/mobile/content_03.component';
import Content04Component from '../../components/mobile/content_04.component';
import Content05Component from '../../components/mobile/content_05.component';
import Content08Component from '../../components/mobile/content_08.component';
import Content09Component from '../../components/mobile/content_09.component';
import ContentDownloadComponent from '../../components/mobile/content_download.component';
import Content06Component from '../../components/mobile/content_06.component';
import Content07Component from '../../components/mobile/content_07.component';
import ContentFooterComponent from '../../components/mobile/content_footer.component';
import ContentFaqComponent from '../../components/mobile/content_faq.component';
import FooterComponent from '../../components/mobile/footer.component';
import { Helmet } from 'react-helmet-async';


const HomeMobileScreen = ({...props}) : React.ReactElement => {

    return (
        <div style={{overflowY : "hidden"}}>
            <FooterComponent />
            <TitleComponent />
            <Content02Component />
            <Content03Component />
            <Content04Component />
            <Content05Component />
            <Content06Component />
            <Content07Component />
            <Content08Component />
            <Content09Component />
            <ContentFaqComponent />
            <ContentDownloadComponent />
            <ContentFooterComponent />
        </div>
    )
}

export default HomeMobileScreen