import React from 'react';
import DownloadComponent from '../../components/common/download.component';
import Footer from '../../components/common/footer.component';
import Main01Component from '../../components/main/main_01.component';
import Main02Component from '../../components/main/main_02.component';
import Main03Component from '../../components/main/main_03.component';
import Main04Component from '../../components/main/main_04.component';
import Main05Component from '../../components/main/main_05.component';
import Main06Component from '../../components/main/main_06.component';
import Main07Component from '../../components/main/main_07.component';
import { Main08Component } from '../../components/main/main_08.component';
import TogetherComponent from '../../components/main/together_info.component';
import './home.styles.css';
import { Helmet } from 'react-helmet-async';

const HomeBroswserScreen =({...props}) : React.ReactElement => {
  
    const appRef = React.useRef<any>(null);
   
    const scrollMove = (value : number) => {
        appRef.current.scrollTo({
        top: value,
        left: 0,
        behavior: "smooth",
        });
    }

    const wheelHandler = (e : any) => {
        e.preventDefault();
        const { deltaY } = e;
        const { scrollTop } = appRef.current; // 스크롤 위쪽 끝부분 위치
        const pageHeight = window.innerHeight; // 화면 세로길이, 100vh와 같습니다.

        if (deltaY > 0) {
            // 스크롤 내릴 때
            if (scrollTop >= 0 && scrollTop < pageHeight) {
                scrollMove(pageHeight)
            } 
            else if (scrollTop >= pageHeight && scrollTop < pageHeight * 2) {
                scrollMove(pageHeight * 2)
            } 
            else if (scrollTop >= pageHeight * 2 && scrollTop < pageHeight * 3) {
                scrollMove(pageHeight * 3)
            }
            else if (scrollTop >= pageHeight * 3 && scrollTop < pageHeight * 4) {
                scrollMove(pageHeight * 4)
            }
            else if (scrollTop >= pageHeight * 4 && scrollTop < pageHeight * 5) {
                scrollMove(pageHeight * 5)
            }
            else if (scrollTop >= pageHeight * 5 && scrollTop < pageHeight * 6) {
                scrollMove(pageHeight * 6)
            }
            else if (scrollTop >= pageHeight * 6 && scrollTop < pageHeight * 7) {
                scrollMove(pageHeight * 7)
            }
            else if (scrollTop >= pageHeight * 7 && scrollTop < pageHeight * 8) {
                scrollMove(pageHeight * 8)
            }
            else {
                appRef.current.scrollTo({
                top: scrollTop + e.deltaY,
                left: 0,
                });
            }
        } 
        
        else {
            if (scrollTop >= 0 && scrollTop < pageHeight) {
                scrollMove(0)
            } 
            else if (scrollTop >= pageHeight && scrollTop < pageHeight * 2) {        
                scrollMove(0)
            }
            else if (scrollTop >= pageHeight * 2 && scrollTop < pageHeight * 3) {
                scrollMove(pageHeight)
            } 
            else if (scrollTop >= pageHeight * 3 && scrollTop < pageHeight * 4) {
                scrollMove(pageHeight * 2)
            } 
            else if (scrollTop >= pageHeight * 4 && scrollTop < pageHeight * 5) {
                scrollMove(pageHeight * 3)
            }
            else if (scrollTop >= pageHeight * 5 && scrollTop < pageHeight * 6) {
                scrollMove(pageHeight * 4)
            } 
            else if (scrollTop >= pageHeight * 6 && scrollTop < pageHeight * 7) {
                scrollMove(pageHeight * 5)
            }
            else if (scrollTop >= pageHeight * 7 && scrollTop < pageHeight * 7) {
                scrollMove(pageHeight * 6)
            }
            else if (scrollTop >= pageHeight * 8 && scrollTop < (pageHeight * 8)+ 30) {
                scrollMove(pageHeight * 7)
            }
            else {
                appRef.current.scrollTo({
                top: scrollTop + e.deltaY,
                left: 0,
                });
            }

        }
    };

    React.useEffect(() => {
        // const outerDivRefCurrent = appRef.current;
        // outerDivRefCurrent.addEventListener("wheel", (e : any) => {
        //     wheelHandler(e);      
        // });
        // outerDivRefCurrent.addEventListener("DOMMouseScroll", (e : any) => {
        //     wheelHandler(e);      
        // });

        // return () => {
        // outerDivRefCurrent.removeEventListener("wheel", (e : any) => wheelHandler(e));
        // outerDivRefCurrent.removeEventListener("DOMMouseScroll", (e : any) => wheelHandler(e));
        // };
    }, []);

    return (
        <div className="outer" ref={appRef}>
            <Main01Component />
            <Main02Component />
            <Main03Component />
            <TogetherComponent />
            <Main04Component />
            <Main05Component />
            <Main06Component />
            <Main07Component />
            <Main08Component />
            <DownloadComponent />
            <Footer />
        </div>
    );

}

export default HomeBroswserScreen;