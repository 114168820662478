import React from 'react';

import AliceCarousel from 'react-alice-carousel';
import { Typography } from '@mui/material';
import BackgroundImage from '../../../assets/images/sitter/mobile/review_background.png';


import './sitter.styles.css';

const Content03Component = ({...props}) : React.ReactElement => {

    const [slideIndex, setSlideIndex] = React.useState<number>(0);
    const [indicator, setIndicator] = React.useState<string>("indicator");

    const data = [
        { "content" : "우주펫에서 펫시팅을 하면 반려인 분들이 감사하다며 선물도 챙겨주셔서 이웃간의 정을 느낄 수 있었어요!", "user" : "서울시 중구 OOO 시터님" },
        { "content" : "수수료가 없어서 좋고 보호자와 소통을 할 수 있는 점이 가장 좋은 것 같아요. 돌봄 요청하는 보호자와 충분히 대화를 나누고 돌볼 수 있다는 점이 좋아요.", "user" : "경기도 광주시 OOO 시터님" },
        { "content" : "우주펫은 보호자 소개글이나, 채팅을 통해 보호자에 대해 파악 할 수 있어서 좋더라구요.", "user" : "경기도 시흥시 OOO 시터님" }
    ]

    const items = [
        <div style={{paddingRight : "1.25vw",}}>
            <div style={styles.contentScreen}>            
                <span style={styles.contentTxt}>
                    {data[0].content}
                </span>
                <span style={styles.userTxt}>
                    {data[0].user}
                </span>

                <img src={BackgroundImage} style={styles.backgroundImage} alt="배경"/>
            </div>
        </div>,
        <div style={{paddingRight : "1.25vw",}}>
            <div style={styles.contentScreen}>            
                <span style={styles.contentTxt}>
                    {data[1].content}
                </span>
                <span style={styles.userTxt}>
                    {data[1].user}
                </span>

                <img src={BackgroundImage} style={styles.backgroundImage} alt="배경"/>
            </div>
        </div>,
        <div style={{paddingRight : "1.25vw",}}>
            <div style={styles.contentScreen}>            
                <span style={styles.contentTxt}>
                    {data[2].content}
                </span>
                <span style={styles.userTxt}>
                    {data[2].user}
                </span>

                <img src={BackgroundImage} style={styles.backgroundImage} alt="배경"/>
            </div>
        </div>        
    ];


    const setIndexChange = (idx : number) => {
        const indexString = ["a", "b", "c"]
        const animationString = indexString[slideIndex] + indexString[idx]

        console.log(animationString)

        if (idx !== slideIndex) {
            setSlideIndex(idx)
            setIndicator(`indicator ${animationString}`)
        }
    }



    return <div style={styles.screen}>

            <span style={styles.titleTxt}>
                이웃시터들의 후기
            </span>

            <div style={{height : "4.6875vh"}}/>

            <AliceCarousel 
                activeIndex={slideIndex}
                mouseTracking
                disableDotsControls
                disableButtonsControls
                items={items}
                autoHeight
                autoWidth
                onSlideChanged={(e) => {
                    setIndexChange(e.item)
                }}
            />

            <div style={{height : "6.2500vh"}}/>

            <div style={styles.indicatorCon}>
                <div className={indicator}/>
            </div>                        
    </div>
}

const styles : Record<string, React.CSSProperties> = {
    "screen" : {
        width : "100vw",     
        display : "flex",
        flexDirection : "column",
        justifyContent : "flex-start",
        alignItems : "flex-start",
        paddingTop : "6.25vh",
        paddingBottom : "13.4375vh",
        paddingLeft : "7.5vw",
        paddingRight : "6.25vw",
        boxSizing : "border-box",
    },
    titleTxt : {
        fontSize : "6.8750vw",
        fontWeight : 700,
        letterSpacing : -0.3,
        alignSelf : "flex-start",
        lineHeight : "140%"
    },
    contentScreen : {
        width : "calc(100vw - 15vw)",
        height : "30.1563vh",
        boxSizing : "border-box",
        display : "flex",
        flexDirection : "column",
        justifyContent : "space-between",
        alignItems : "flex-start",
        paddingLeft : "7.5vw",
        paddingRight : "7.5vw",
        paddingTop : "11.25vw",
        paddingBottom : "7.5vw",
        backgroundColor : "transparent",
    },    
    contentTxt : {
        fontSize : "4.6875vw",
        fontWeight : 400,
        color : "#62686D",
        lineHeight : "150%",
    },
    userTxt : {
        fontSize : "3.7500vw",
        fontWeight : 400,
        color : "#9FA5AD",
        lineHeight : "140%",
    },
    indicatorCon : {
        width : "42.5000vw",
        height : "1.2500vw",
        backgroundColor : "#F4F4F5",
        borderRadius : 20
    },
    backgroundImage : {
        width : "calc(100vw - 15vw)",
        height : "30.1563vh",
        position : "absolute",
        top : 0,
        left : 0,
        zIndex : -1,
    }
}

export default Content03Component;