import React from 'react';
import "../common/common.styles.css";
import Apple from '../../assets/icon/component/download/apple.svg';
import Google from '../../assets/icon/component/download/google.svg';
import {  AppStoreWebLink, PlayStoreWebLink } from '../../data/urls';

const DownloadComponent = ({...props}) : React.ReactElement => {

    const pressApple = () => {
        window.open(AppStoreWebLink, "_blank")        
    }

    const pressGoogle = () => {
        window.open(PlayStoreWebLink,  "_blank")
    }


    return (
        <div className='downloadCon'>

            <div className="donwloadTitleCon">
                <p className="downloadTitle">
                    지금 우주펫에서<br />
                    우리동네 견주/집사님을 찾아보고<br/>
                    친하게 지낼 이웃 반려인을 만나보세요!

                    <div className="downloadBtnCon">

                    <script>
                                window.karrotPixel.init('1695801066361400001');
                                window.karrotPixel.track('Lead');
                            </script>

                        <img src={Apple} alt="" className='AppleBtn' onClick={() => pressApple()}>

                        </img>
                        <img src={Google} alt="" className="GoogleBtn" onClick={() => pressGoogle()}/>
                    </div>
                </p>
            </div>



        </div>
    )
}

export default DownloadComponent