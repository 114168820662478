import React from 'react';
import './main.styles.css';

import Question from '../../assets/icon/main/main_08.svg';

export const Main08Component = ({...props}) : React.ReactElement => {

    const [visible01, setVisible01] = React.useState(false);
    const [visible02, setVisible02] = React.useState(false);
    const [visible03, setVisible03] = React.useState(false);
    const [visible04, setVisible04] = React.useState(false);
    const [visible05, setVisible05] = React.useState(false);

    const press01 = () => {
        setVisible01(!visible01);
    }

    const press02 = () => {
        setVisible02(!visible02);
    }

    const press03 = () => {
        setVisible03(!visible03);
    }

    const press04 = () => {
        setVisible04(!visible04);
    }

    const press05 = () => {
        setVisible05(!visible05);
    }


    return (
        <div className="main08Con">

            <p className="main08TitleText">FAQ</p>

            <div className="main08QnaCon">
                <div className="main08QnaTitleCon" onClick={() => press01()}>
                     <img src={Question} alt="" className="main08QnaMark"/>
                    <p className="main08QnaTitleText">우주펫은 정말 반려인들만 있나요?</p>
                </div>

                {(visible01)? 
                   <p className="main08QnaContentText">
                    우주펫은 두 가지로 반려인 검증을 진행합니다.<br/>
                    우선 회원가입 시 현재 양육 중인 반려동물의 다른 사진 3장을 필수적으로 입력받고, 우주펫 팀이 직접 확인하여 회원가입을 승인합니다. (현재 2,000명 이상의 유저가 승인에 실패하여 서비스 이용이 제한되고 있습니다). 두 번째는 유저들에게 동물등록번호인증을 받고 있습니다. 유저의 프로필에 동물등록번호 인증이 완료된 확실한 반려인만을 확인할 수 있습니다. 
                   </p>
                :
                    null
                }                
            </div>

            <div className="main08QnaCon">
                <div className="main08QnaTitleCon" onClick={() => press02()}>
                     <img src={Question} alt="" className="main08QnaMark"/>
                    <p className="main08QnaTitleText">우주펫에서 품앗이 이웃찾기는 무료서비스인가요?</p>
                </div>

                {(visible02)? 
                    <p className="main08QnaContentText">
                        네, 우주펫은 따로 비용을 받지 않는 무료 서비스입니다. 우리 동네 견주/집사님과 부담 없이 돌봄을 주고받아 보세요!
                    </p>
                :
                    null
                }

            </div>

            <div className="main08QnaCon">
                <div className="main08QnaTitleCon" onClick={() => press03()}>
                    <img src={Question} alt="" className="main08QnaMark"/>
                    <p className="main08QnaTitleText">현재 서비스 오픈 지역은 어디인가요?</p>
                </div>

                {(visible03)? 
                    <p className="main08QnaContentText">
                        우주펫은 현재 전국에서 서비스를 제공하고 있어요.
                    </p>
                :
                    null
                }                
            </div>

            <div className="main08QnaCon">
                <div className="main08QnaTitleCon" onClick={() => press04()}>
                     <img src={Question} alt="" className="main08QnaMark"/>
                    <p className="main08QnaTitleText">실종신고 글을 작성하려면 비용을 지불해야 하나요?</p>
                </div>

                {(visible04)? 
                    <p className="main08QnaContentText">
                        실종신고 역시 무료입니다. 아이가 더 멀리 가기 전에 우주펫에 빠르게 신고하시고, 동네 이웃분들에게 소식을 공유해보세요!
                    </p>
                :
                    null
                }
            </div>

            <div className="main08QnaLastCon">
                <div className="main08QnaTitleCon" onClick={() => press05()}>
                     <img src={Question} alt="" className="main08QnaMark"/>
                    <p className="main08QnaTitleText">돌봄 SOS는 비용을 지불해야 하나요?</p>
                </div>

                {(visible05)? 
                    <p className="main08QnaContentText">
                        급하게 돌봄이 필요한 경우 돌봄 SOS 글은 기프티콘, 상품권, 현금 등의 “사례”를 자율적으로 적고 있습니다. 돌봄 후에 감사의 마음으로 사례를 직접 전달해보세요. ( *우주펫에서 수수료를 받지 않습니다. )
                    </p>
                :
                    null
                }
            </div>

        </div>
    )
}