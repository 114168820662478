
import React from 'react';
import '../sitter.styles.css';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ThanksMascot from '../../../../assets/icon/mascot/thanks_mascot.svg'
import BlogButton from '../../../../assets/icon/sitter/mobile/blog.svg';
import InstaButton from '../../../../assets/icon/sitter/mobile/insta.svg';

const WriteDoneBrowserComponent = ({...props}) : React.ReactElement => {

    const [searchParams, setSearchParams] = useSearchParams();
    const name = searchParams.get("name")

    const navigate = useNavigate();

    const onDone = () => {
        navigate("/")
    }

    const onBlog = () => {
        window.open("https://blog.naver.com/spacepetofficial", "_blank")
    }

    const onInsta = () => {
        window.open("https://www.instagram.com/space.pet_official/", "_blank")
    }

    return <div style={styles.screen}>
        <div style={styles.mainCon}>
            <img src={ThanksMascot} style={styles.mascot} alt="마스코트"/>
            <span style={styles.title}>지원해주셔서 감사해요</span>
            <span style={styles.content}>{name} 지원자님, 지원이 완료됐어요.<br/>결과는 지원서 접수 후<br/>영업일 기준 3일 이내에<br/>결과를 문자로 보내드려요.</span>
            <div style={styles.button} onClick={() => onDone()}>
                확인
            </div>
        </div>

        <div style={{height : "9.3750vh"}} />

        <span style={styles.subTitle}>우주펫 최신 소식 보러가기</span>

        <div style={styles.rowCon}>
            <img src={BlogButton} style={styles.iconButton} alt="버튼"onClick={() => onBlog()}/>
            <div style={{width : "12.5000vw"}}/>
            <img src={InstaButton} style={styles.iconButton} alt="버튼" onClick={() => onInsta()}/>
        </div>
    </div>

}


const styles : Record<string, React.CSSProperties> = {
    screen : {
        width : 430,   
        display : "flex",
        flexDirection : "column",
        justifyContent : "flex-start",
        alignItems : "flex-start",
        paddingTop : 70,
        boxSizing : "border-box",
        paddingLeft: 24,
        paddingRight : "7.5vw",     
    },
    mainCon : {
        width : "100%",
        backgroundColor : "#F9F5FF",
        borderRadius : "10px",
        paddingTop : 26,
        paddingBottom : 26,
        paddingLeft : 22,
        paddingRight : 22,
        display : "flex",
        flexDirection : 'column',
        alignItems : "flex-start",
        justifyContent : "flex-start",
        boxSizing : "border-box"
    },
    mascot : {
        width : 110,
        height : 74
    },
    title : {
        color : "black",
        fontSize : 20,
        fontWeight : 700,
        lineHeight : "100%",
        letterSpacing : "-2%",
        marginTop : 10,
        marginBottom : 20,
        marginLeft : 0,
        marginRight : 0,
    },
    content : {
        color : "#62686D",
        fontSize : 15,
        fontWeight : 400,
        lineHeight : "150%",
        letterSpacing : "-3%",
        marginTop : 0,
        marginBottom : 20,
        marginLeft : 0,
        marginRight : 0,
    },
    button : {
        width : "100%",
        borderRadius : "10px",
        display : "flex",
        alignItems : "center",
        justifyContent : "center",
        boxSizing : "border-box",
        paddingTop : 11.5,
        paddingBottom : 11.5,
        paddingLeft : 0,
        paddingRight : 0,
        color : "white",
        fontSize : 16,
        fontWeight : 700,
        lineHeight : "130%",
        letterSpacing : "-1%",
        backgroundColor : "#9F6BFF"
    },
    subTitle : {
        display : "flex",
        alignSelf : "center",
        color : "black",
        fontSize : 14,
        fontWeight : 600,
        lineHeight : "120%",
        letterSpacing : "-2%",
        textAlign : "center",
        marginTop : 0,
        marginBottom : 21,
        marginLeft : 0,
        marginRight : 0,
    },
    rowCon : {
        width : "100%",
        display : "flex",
        alignItems : "center",
        justifyContent : "center",        
    },
    iconButton : {
        width : 54,
        height : 70,
    }
}



export default WriteDoneBrowserComponent