import React from 'react';

import TitleLogo from '../../../assets/icon/sitter/mobile/title_logo.svg';
import { useNavigate } from 'react-router-dom';


const HeaderComponent = ({...props}) : React.ReactElement => {

    const navigate = useNavigate();

    const pressMain = () => {
        navigate("/")
    }


    return <div style={styles.header}>
        <img src={TitleLogo} style={styles.titleLogo} alt="로고" onClick={() => pressMain()}/>
    </div>
}

const styles : Record<string, React.CSSProperties> = {
    "header" : {
        width : "100vw",
        height : "18.7500vw",
        backgroundColor : "white",
        display : 'flex',
        top : 0,
        left : 0,
        position : "fixed",
        paddingTop : "2.6563vh",
        paddingBottom : "2.0313vh",
        paddingLeft : "7.5000vw",
        zIndex : 1000,
        boxSizing : "border-box"
    },
    "titleCon" : {
        display : "flex",
        boxSizing : "border-box",
        paddingBottom : 0,
    },
    "titleLogo" : {
        width : "30vw",
        height : "9.3750vw",
        cursor : "pointer"
    }
}

export default HeaderComponent;