import React from 'react';

import BackgroundImage from '../../../assets/images/sitter/mobile/condition_background.png'

const Content05Component = ({...props}) : React.ReactElement => {

    return <div style={styles.screen}>
        <span style={styles.titleTxt}>
            지원 자격
        </span>

        <img src={BackgroundImage} style={styles.backgroundImage} alt="이미지"/>

    </div>
}

const styles : Record<string, React.CSSProperties> = {
    screen : {
        width : "100vw",
        backgroundColor : "white",
        display : "flex",
        flexDirection : "column",
        boxSizing : "border-box",
        paddingTop : "7.8125vh",
        paddingBottom : "6.7188vh",
    },
    titleTxt : {
        fontSize : "6.8750vw",
        fontWeight : 700,
        letterSpacing : -0.3,
        alignSelf : "flex-start",
        paddingLeft: "7.5vw",
        lineHeight : "140%"
    },
    backgroundImage : {
        width : "100%",
        marginTop : "6.25vh"
    }
}

export default Content05Component;