import React from 'react';

import info from '../../../assets/icon/sitter/mobile/recuirt.svg';

const Content06BrowserComponent = ({...props}) : React.ReactElement => {

    return <div style={styles.screen}>
        <span style={styles.titleTxt}>
            선발절차
        </span>

        <img src={info} style={styles.backgroundImage} alt="이미지"/>

    </div>
}

const styles : Record<string, React.CSSProperties> = {
    screen : {
        width : 430,
        backgroundColor : "white",
        display : "flex",
        flexDirection : "column",
        boxSizing : "border-box",
        paddingTop : 50,
        paddingBottom : 73,
        paddingLeft: 24,
        paddingRight : 24,
    },
    titleTxt : {
        fontSize : 22,
        fontWeight : 700,
        letterSpacing : -0.3,
        alignSelf : "flex-start",
    },
    backgroundImage : {
        width : "100%",
        marginTop : "6.25vh",
        lineHeight : "140%"
    }
}

export default Content06BrowserComponent;