import React from 'react';

// 컨텐트 이미지
import TitleLogo from '../../assets/icon/logo/logo_01.svg';
import Content from '../../assets/image/mobile/title/title_background.png'

// 스타일
import './mobile.styles.css';
import { useNavigate } from 'react-router-dom';

const TitleComponent = ({...props}) : React.ReactElement => {

    // 모바일 컴포넌트 
    // 타이틀 컴포넌트 01

    const navigate = useNavigate();

    const onClick = () => {
        window.open("https://cosmicnyang.page.link/QYkB")
    }

    const onSitter = () => {
        navigate("/sitter")
    }

    return (
        <div className='main02'>

            <div className="titleLogoCon">
                <img src={TitleLogo} alt="타이틀" className='title' style={{width : "37.5vw"}}/>
                <span className="titleLogoTxt" onClick={() => onSitter()}>펫시터 신청하기</span>
            </div>
            

            {/* <div style={{height : "11.5625vw"}}/> */}

            <p className='titleTxt'>반려동물 돌봐줄<br/>이웃을 만나보세요</p>
            
            <div className="titleDownBtn" onClick={() => {
                onClick();
            }}>
                <p>{"바로 만나러 가기 >"}</p>
            </div>

            <img src={Content} alt="컨텐츠" className='content'/>
        </div>
    )
}

export default TitleComponent