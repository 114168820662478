import React from 'react';

// 컨텐트 이미지
import ContentImage from '../../assets/image/mobile/content/home_content_05.png'

// 스타일
import './mobile.styles.css';

const Content05Component = ({...props}) : React.ReactElement => {

    // 모바일 컴포넌트 
    // 타이틀 컴포넌트 01

    return (
        <div className='main' style={{backgroundColor : "#F9FAFF"}}>            
            <p className="contentTitle01">믿을 수 있는 이웃</p>
            <p className="contentTitle02">상대 프로필 보고<br/>이웃 만나보기</p>
            <p className="contentTitle03">아무에게나 우리 아이를 맡길 수는 없겠죠?<br/>상대 프로필 보고 이웃을 만나보세요.</p>

            <div style={{height : "4.0625vw"}}/>

            <img src={ContentImage} alt="컨텐츠" className='content'/>
        </div>
    )
}

export default Content05Component