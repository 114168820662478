import React from 'react';
import './main.styles.css';

import Logo from '../../assets/icon/common/logo.svg';
import Title from '../../assets/icon/common/title_logo.svg';
import { useNavigate } from 'react-router-dom';

const Main01Component = ({...props}) : React.ReactElement => {

    const navigate = useNavigate();

    const pressSitter = () => {
        navigate("/sitter")
    }



    return (
        <div className="main01Con">

            <div className="mainTop">
                <img src={Logo} alt="" className="mainLogo"/>

                <p onClick={() => {pressSitter()}}>시터 지원하기</p>
            </div>

            <div className="main01SemiCon">
                <div className="main01TextCon">
                    <p className="main01Title">우리 주변의 펫</p>

                    <img src={Title} alt="" className="mainTitleLogo"/>

                    <p className="main01Content">
                        내 주변 반려인들을 둘러보고,<br/>
                        우리동네 견주/집사님들과<br/>
                        다양한 활동을 같이해보세요!
                    </p>
                </div>


                <img 
                    src={require("../../assets/images/main/main_01.png")} 
                    className='main01Image'
                    alt="" 
                />

            </div>

            
            <div />

        </div>
    )
}

export default Main01Component