import React from 'react';
import './main.styles.css';

import Main02Text from '../../assets/icon/main/main_02.svg';


const Main02Component = ({...props}) : React.ReactElement => {


    return (
        <div className="mainCon">

            <img alt="" src={Main02Text} className="main02Content"/>

            <img alt="" src={require("../../assets/images/main/main_02.png")} className="mainImage" width={710}/>

        </div>
    )
}

export default Main02Component