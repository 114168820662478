import React from 'react';

// 컨텐트 이미지
import ContentImage from '../../assets/image/mobile/content/home_content_07.png'

// 스타일
import './mobile.styles.css';

const Content07Component = ({...props}) : React.ReactElement => {

    // 모바일 컴포넌트 
    // 타이틀 컴포넌트 01

    return (
        <div className="main07">            
            <img src={ContentImage} alt="컨텐츠" className='content'/>
        </div>
    )
}

export default Content07Component