import React from 'react';

// 컨텐트 이미지
import ContentImage from '../../assets/image/mobile/content/home_content_09.png'

// 스타일
import './mobile.styles.css';

const Content09Component = ({...props}) : React.ReactElement => {

    // 모바일 컴포넌트 
    // 타이틀 컴포넌트 01

    return (
        <div className='main' style={{backgroundColor : "#F9FAFF"}}>            
            <p className="contentTitle01">산책친구</p>
            <p className="contentTitle02">우리아이와 잘 맞는<br/>산책친구 만나기</p>
            <p className="contentTitle03">우주펫에서 산책 친구를 만나보세요.<br/>거리・시간대에 맞는 상대를 찾을 수 있어요.</p>

            <div style={{height : "4.0625vw"}}/>

            <img src={ContentImage} alt="컨텐츠" className='content'/>
        </div>
    )
}

export default Content09Component